/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.theme-default, .td {
	color: var(--foreground-default) !important;
	background: var(--background-default) !important;
}

.foreground-default, .fd {
	color: var(--foreground-default) !important;
}

.background-default, .bd {
	background: var(--background-default) !important;
	background-size: cover !important;
}

.theme-secondary, .ts {
	color: var(--foreground-secondary) !important;
	background: var(--background-secondary) !important;
}

.foreground-secondary, .fs {
	color: var(--foreground-secondary) !important;
}

.background-secondary, .bs {
	background: var(--background-secondary) !important;
}

.theme-tertiary, .tt {
	color: var(--foreground-tertiary) !important;
	background: var(--background-tertiary) !important;
}

.foreground-tertiary, .ft {
	color: var(--foreground-tertiary) !important;
}

.background-tertiary, .bt {
	background: var(--background-tertiary) !important;
}

.theme-quaternary, .tq {
	color: var(--foreground-quaternary) !important;
	background: var(--background-quaternary) !important;
}

.foreground-quaternary, .fq {
	color: var(--foreground-quaternary) !important;
}

.background-quaternary, .bq {
	background: var(--background-quaternary) !important;
}

.theme-button-primary, .tbp {
	background: var(--button-primary-background) !important;
	color: var(--button-primary-foreground) !important;
}

.tbp:disabled {
	background: var(--button-primary-background-disabled) !important;
	color: var(--button-primary-foreground-disabled) !important;
}

.button-primary-background, .bpb {
	background: var(--button-primary-background) !important;
}

.button-primary-foreground, .bpf {
	color: var(--button-primary-foreground) !important;
}

.theme-button-secondary, .tbs {
	background: var(--button-secondary-background) !important;
	color: var(--button-secondary-foreground) !important;
}

.tbs:disabled {
	background: var(--button-secondary-background-disabled) !important;
	color: var(--button-secondary-foreground-disabled) !important;
}

.button-secondary-background, .bsb {
	background: var(--button-secondary-background) !important;
}

.button-secondary-foreground, .bsf {
	color: var(--button-secondary-foreground) !important;
}

.theme-button-accent, .tba {
	background: var(--button-accent-background) !important;
	color: var(--button-accent-foreground) !important;
}

.button-accent-background, .bab {
	background: var(--button-accent-background) !important;
}

.button-accent-foreground, .baf {
	color: var(--button-accent-foreground) !important;
}

.theme-button-danger, .tbd {
	background: var(--button-danger-background) !important;
	color: var(--button-danger-foreground) !important;
}

.button-danger-background, .bdb {
	background: var(--button-danger-background) !important;
}

.button-danger-foreground, .bdf {
	color: var(--button-danger-foreground) !important;
}

.shadow {
	box-shadow: var(--shadow) !important;
}

.ti {
	background-color: inherit !important;
	color: inherit !important;
}

.bgi {
	background-color: inherit !important;
}

.fgi {
	color: inherit !important;
}

.error-icon {
	color: var(--error-icon) !important;
}

.orb {
 	background-color: var(--or-background) !important;
}

.orf {
	color: var(--or-foreground) !important;
}

.or {
	background-color: var(--or-background) !important;
	color: var(--or-foreground) !important;

	.ql-editor {
		color: var(--or-foreground) !important;
		background-color: var(--or-background) !important;
	}
}

.cbg {
	background-color: var(--chart-background) !important;
}

.lbg {
	background-color: var(--loading-background) !important;
}

.bpg {
	background-color: var(--progress-color) !important;
}

.mbg {
	background-color: var(--dialog-background) !important;
}

.mcaf {
	color: var(--mca-foreground) !important;
}

.mcab {
	background-color: var(--mca-background) !important;
}

.mca {
	color: var(--mca-foreground) !important;
	background-color: var(--mca-background) !important;
}

mat-spinner {
	svg {
		circle {
			filter: var(--spinner) !important;
		}
	}
}

.ql-formats {
	svg {
		.ql-stroke {
			stroke: var(--or-foreground) !important;
		}

		.ql-fill {
			fill: var(--or-foreground) !important;
		}
	}

	.ql-picker, .ql-picker-options, .ql-picker-label {
		background-color: var(--or-background) !important;
		color: var(--or-foreground) !important;

		
	}
}

/* Bootstrap overrides */

.m-height-10 {
	min-height: 10% !important;
}

.m-height-80 {
	min-height: 80% !important;
}

.m-height-50 {
	min-height: 50% !important;
}

.mx-height-50 {
	max-height: 50% !important;
	overflow-y: scroll !important;
}

.h-10 {
	height: 10% !important;
}

.h-80 {
	height: 80% !important;
}

.flex-1 {
	flex: 1;
}

/* Misc */
.clickable {
	cursor: pointer;
}
/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap.scss';

/* Quill Font size dropdown */
.ql-snow .ql-picker.ql-size {
	.ql-picker-label,
	.ql-picker-item {
		&[data-value="8px"]::before {
			content: '8px';
		}
		&[data-value="10px"]::before {
			content: '10px';
		}
		&[data-value="12px"]::before {
			content: '12px';
		}
		&[data-value="14px"]::before {
			content: '14px';
		}
		&[data-value="16px"]::before {
			content: '16px';
		}
		&[data-value="18px"]::before {
			content: '18px';
		}
		&[data-value="20px"]::before {
			content: '20px';
		}
	}
	.ql-picker-item {
		&[data-value="8px"]::before {
			font-size: 8px;
		}
		&[data-value="10px"]::before {
			font-size: 10px;
		}
		&[data-value="12px"]::before {
			font-size: 12px;
		}
		&[data-value="14px"]::before {
			font-size: 14px;
		}
		&[data-value="16px"]::before {
			font-size: 16px;
		}
		&[data-value="18px"]::before {
			font-size: 18px;
		}
		&[data-value="20px"]::before {
			font-size: 20px;
		}
	}
}

.error-text {
	color: red;
	padding: 5px;
}

.section-container {



	.mat-card-header-text {
		margin: 0 !important;
		text-align: center;
	}
}

.mat-mdc-button-base {
	display: flex;
	justify-content: center;
	align-items: center;
	
	
	
	.mat-button-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

/* Mat Button success colours */

.mat-mdc-raised-button.mat-success,
.mat-mdc-unelevated-button.mat-success,
.mat-mdc-fab.mat-success,
.mat-mdc-mini-fab.mat-success {
  color: #f0fff3;
  background-color: #2d9045;
}

.mat-mdc-dialog-container {
	color: inherit !important;
    background-color: inherit !important;
}

.saving-snackbar {
	background: var(--button-primary-background) !important;
	color: var(--button-primary-foreground) !important;
}

.ql-container.ql-snow > .ql-editor > p:empty {
	margin-bottom: 1rem;
}
